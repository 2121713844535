export const LARGE_PRODUCT_ACTION_BUTTON_ADDNOTE_FRAGMENT = `
  fragment largeProductActionButtonAddnoteFragment on LargeProduct {
    __typename
    id
    uid
    name
    images(orderBy: [id_asc]) {
      fullFileResource {
        schemaCode
        path
      }
    }
  }
`;
